import baseURL from "../baseUrl";
import { timeZone } from "../constants";
import getAccessToken from "../../accessToken";
import {
  ChangePasswordParameters,
  MinimumOrderParameter,
  SaveEstablishmentParameter,
  UpdateDeliveryParameter,
  UpdateEstablishmentParameter,
  UpdatePasswordParameters,
  UpdateProfileParameters,
  UpdateScheduleParameter,
  UpdateStatusParameter,
  UpdateTakeAwayParameter,
} from "./types";

class Profile {
  async getProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/profile`, {
      method: "get",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",

      },
    });
    return response.json();
  }
  async updateProfile(data: UpdateProfileParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/profile-update`, {
      method: "put",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "ngrok-skip-browser-warning": "69420",

      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async updatePassword(data: UpdatePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/update-password`, {
      method: "post",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "ngrok-skip-browser-warning": "69420",

      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
  async changePasswords(data: ChangePasswordParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/user/profile/changePassword`, {
      method: "post",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "ngrok-skip-browser-warning": "69420",

      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async updateMinimumOrder(data: MinimumOrderParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/minimum-establishment-update/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async updateEstablishment(data: UpdateEstablishmentParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/update-establishment/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async getSchedule() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/get-schedule`, {
      method: "get",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "ngrok-skip-browser-warning": "69420",

      },
    });
    return response.json();
  }

  async updateSchedule(data: UpdateScheduleParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/create-or-update-schedule/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async updateDelivery(data: UpdateDeliveryParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/delivery-establishment-update/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async updateTakeaway(data: UpdateTakeAwayParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/take-way-establishment-update/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
        
      }
    );
    return response.json();
  }

  async updateStatus(data: UpdateStatusParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${baseURL}/vendor/close-open-establishment/${data.id}`,
      {
        method: "put",
        headers: {
          timezone: timeZone,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "ngrok-skip-browser-warning": "69420",

        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  async saveEstablishment(data: SaveEstablishmentParameter) {
    const accessToken = getAccessToken();
    const formData = new FormData();
  
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof SaveEstablishmentParameter];
      if (value !== null && value !== undefined) {
        formData.append(key, value as string | Blob);
      }
    });
  
    const response = await fetch(`${baseURL}/vendor/save-establishment`, {
      method: "POST",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "ngrok-skip-browser-warning": "69420",

      },
      body: formData,
    });
    return response.json();
  }
  
  

  async deleteProfile() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/vendor/delete`, {
      method: "delete",
      headers: {
        timezone: timeZone,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "ngrok-skip-browser-warning": "69420",

      },
    });
    return response.json();
  }
}

const instance = new Profile();

export default instance;
