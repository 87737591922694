import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "./types";
import { useActionData } from "react-router-dom";

const initialStateValue: UserType = {
  id: "",
  country: null,
  account_no: "",
  account_status: "",
  bank_name: "",
  branch: "",
  country_id: 0,
  city_id: 0,
  device_token: "",
  email: "",
  email_verified_at: "",
  first_name: "",
  holder_name: "",
  image: "",
  last_name: "",
  password: "",
  phone: 0,
  vendor_id: 0,
  establishment: null,
  phone_verified_at: "",
  state_id: 0,
  status: false,
  temp_token: "",
  zone_id: 0,
  created_at: "",
  updated_at: "",
  has_kyc: false,
  loggedIn: false,
  kyc_status: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      // Set Token
      sessionStorage.setItem("accessToken", action.payload.access_token);
      sessionStorage.setItem("kyc_status", action.payload.kyc_status);
      if (action.payload.remember_me) {
        localStorage.setItem("accessToken", action.payload.access_token);
      }
      state.value = action.payload;
    },
    setUser: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.value = initialStateValue;
    },

    setEstablishment: (state, action) => {
      state.value = {...state.value, establishment: action.payload}
    }
  },
});

export const { login, setUser, logout, setEstablishment } = userSlice.actions;
export default userSlice.reducer;
